import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';


interface Images {
    img1: string,
    img2: string,
    id: number
}


@Component
export default class MainComponent extends Vue {
    @Prop() image!: string
    @Prop() asseturl!: string
    @Prop() interval!: string

    parsedData: Array<Images> | [] = [];
    img1 = ''
    img2 = ''
    animate: boolean = false;
    id: number = 0
    timer: any = ''
    mounted() {
        let val = this.image.replace(/'/g, `"`)
        this.parsedData = JSON.parse(val).map((item: Images, i: number) => ({ img1: item.img1, img2: item.img2, id: i }))
        this.img1 = this.parsedData[0].img1
        this.img2 = this.parsedData[0].img2
        this.id = 0

        this.reset()
    }
    reset() {
        this.timer = setInterval(() => {
            if (this.id == this.parsedData.length - 1) {
                this.id = 0
                this.img1 = this.parsedData[0].img1
                this.img2 = this.parsedData[0].img2

            } else {
                this.id++
                this.img1 = this.parsedData[this.id].img1
                this.img2 = this.parsedData[this.id].img2
            }

        }, parseInt(this.interval))
    }
    log(el: any, done: any) {
        console.log("it works")
        done()
    }

}