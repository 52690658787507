<template>
  <div class="vue-modal">
    <div class="content">
      <section class="home_slider">
        <div class="container">
          <div class="bx-wrapper" style="max-width: 100%;">
            <div class="Cheader" aria-live="polite">
              <ul class="bxslider" style="width: auto; position: relative;">
                <li class="Citem">
                  <div class="col-md-4">
                    <div class="row">
                      <div class="slider_right_text" data-animation="fadeInUp" data-duration="500">
                        <img
                          id="ctl00_ContentPlaceHolderSiteContent_sliderrighttext4"
                          alt="Award winning Embroidery Digitizing Design	"
                          :src="asseturl+'/images/slider-text.png'"
                          style="border-width:0px;"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-8">
                    <div class="slider_home_screen">
                      <div class="small_image" data-animation="fadeInLeft" data-duration="1200">
                        <transition name="slide-fade">
                          <img
                            class="imgg"
                            alt="Award winning Embroidery Digitizing Design	"
                            :src="img1"
                            :key="img1"
                            style="border-width:0px;"
                          />
                        </transition>
                      </div>

                      <div
                        class="arrow_on_image animate fadeInDown animated"
                        data-animation="fadeInDown"
                        data-duration="1500"
                      >
                        <img
                          class="imgA"
                          alt="arrow"
                          :src="asseturl+'/images/arrow-slider.png'"
                          style="border-width:0px;"
                        />
                      </div>

                      <transition name="slide-fade">
                        <div class="zoom_image animate fadeIn animated">
                          <img
                            alt="Award winning Embroidery Digitizing Design	"
                            :src="img2"
                            class="imgl"
                            :key="img2"
                            style="border-width:0px;"
                          />
                        </div>
                      </transition>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="bx-controls bx-has-controls-direction bx-has-pager">
              <div class="bx-controls-direction">
                <a class="bx-prev" href>Prev</a>
                <a class="bx-next" href>Next</a>
              </div>
              <div class="bx-pager bx-default-pager">
                <div
                  class="bx-pager-item"
                  @click="img1=parsedData[items.id].img1;img2=parsedData[items.id].img2;id=items.id"
                  v-for="items in parsedData"
                  :key="items.id"
                >
                  <a
                    href="javascript:void(0)"
                    class="bx-pager-link"
                    :class="items.id===id ?'active':''"
                  >{{items.id}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import MainComponent from "./main";

export default class MainView extends MainComponent {}
</script>
<style lang="scss">
@import "../assets/scss/index";
</style>